<template>
  <div class="payment-status">
    <div class="header acea-row row-between-wrapper" ref="header">
      <div class="black" @click="returnChange()">
        <img src="@assets/images/icon_black.png" alt="" />
      </div>
      <p>支付订单</p>
      <p class="admin" @click="getMyOrder">我的订单</p>
    </div>

    <div class="order-container acea-row row-center">
      <div class="content">
        <p class="title">支付时间剩余</p>
        <div class="time">
          <p>{{msg}}</p>
        </div>
        <p class="txt">已为您预留库存，请尽快付款</p>
      </div>
      <div class="list acea-row row-between">
        <p>订单号：</p>
        <p>{{orderInfo.order_id}}</p>
      </div>
      <div class="list acea-row row-between">
        <p>应付金额：</p>
        <p class="amount">￥{{orderInfo.total_price}}</p>
      </div>
<!--      <div class="list acea-row row-between">-->
<!--        <p>运费：</p>-->
<!--        <p class="amount">￥{{orderInfo.total_price}}</p>-->
<!--      </div>-->
    </div>



    <div class="item-container">
      <div class="item acea-row row-between">
        <div class="left acea-row row-between">
          <img src="@assets/images/icon_wx2.png" alt="" />
          <p>微信支付</p>
        </div>
        <div class="right">
          <div class="checkbox-wrapper">
            <label class="well-check">
              <input
                type="radio"
                v-model="isAllChecked"
                value="1"
              />
              <i class="icon"></i>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="item-container" v-if="from=='weixinh5'">
      <div class="item acea-row row-between">
        <div class="left acea-row row-between">
          <img src="@assets/images/icon_zhifubao.png" alt="" />
          <p>支付宝支付</p>
        </div>
        <div class="right">
          <div class="checkbox-wrapper">
            <label class="well-check">
              <input
                type="radio"
                v-model="isAllChecked"
                value="2"
              />
              <i class="icon"></i>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="item-container">
      <div class="item acea-row row-between">
        <div class="left acea-row row-between">
          <img src="@assets/images/icon_yinhang.png" alt="" />
          <p>银行转账</p>
        </div>
        <div class="right">
          <div class="checkbox-wrapper">
            <label class="well-check">
              <input
                type="radio"
                v-model="isAllChecked"
                value="3"
              />
              <i class="icon"></i>
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-btn acea-row row-between" v-if="isAllChecked==1">
      <div class="right pay" @click="toPay">
        <p>去支付</p>
      </div>
    </div>
    <div class="detail-btn acea-row row-between" v-if="isAllChecked==2">
      <div class="right pay" @click="toPayAliPay">
        <p>去支付</p>
      </div>
    </div>
    <div class="detail-btn acea-row row-between" v-if="isAllChecked==3">
      <div class="right pay" @click="toPayYinhang">
        <p>去支付</p>
      </div>
    </div>
    <!-- <div v-html="resultData" ref="alipayWap"></div> -->
    <div id="alipayWap"></div>
  </div>
</template>


<script type="text/javascript">
import { payPage } from "@api/order";
import { payOrderHandle } from "@libs/order";
import { isWeixin } from "@utils";
const NAME = "PayMentStatus";
export default {
  name:NAME,
  data(){
    return {
      radio2:'',
      isAllChecked:isWeixin()? 1:2,
      id:'',
      status: 0,
      orderInfo:"",
      msg:'',
      from: isWeixin()? "weixin" : "weixinh5",
      type:'weixin',
      resultData:'',
    }
  },
  watch: {
    $route(n) {
      if (n.query.msg) this.msgContent = n.query.msg;
      if (n.name === NAME && this.id !== n.params.id) {
        this.id = n.params.id;
        this.status = parseInt(n.params.status);
        this.getOrderInfo();
      }
    }
  },
  mounted(){
    this.id = this.$route.params.id;
    this.getOrderInfo();
  },
  methods:{
    //返回上一页
    returnChange() {
      var that = this;
      that.$router.go(-1);
    },
    //我的订单
    getMyOrder:function(){
      var that = this;
      that.$router.push({
        path: "/order/list/",
      });
    },
  //点击去支付
   toPay() {
      var that = this;
      payOrderHandle(this.orderInfo.order_id, that.type, that.from)
        .then((res) => {
          that.$dialog.toast({
            mes: res.msg,
            callback: () => {
              that.$router.go(-1);
            }
          });
          console.log(res);
        })
        .catch(res => {
          if (res.status === "WECHAT_H5_PAY")
            that.$router.push({
              path: "/order/status/" + that.orderInfo.order_id + "/0"
            });
        });
   },

   //支付宝支付
   toPayAliPay() {
      var that = this;
      var div = document.getElementById('alipayWap');
      payOrderHandle(this.orderInfo.order_id, 'aliPay', 'aliPay')
        .then((res) => {
          div.innerHTML = res.result.html;
          document.forms[0].target='_self';
          document.forms[0].submit();
        })
        .catch(res => {
          if (res.status === "WECHAT_H5_PAY")
            that.$router.push({
              path: "/order/status/" + that.orderInfo.order_id + "/0"
            });
        });
   },
   //银行转账
   toPayYinhang() {
      var that = this;
      that.$router.push({
        path: "/yinhangpay?order_id=" + that.orderInfo.order_id + "&total_price=" + that.orderInfo.total_price,
      });
   },

    //获取订单数据
    getOrderInfo() {
      payPage(this.id).then(res => {
        this.orderInfo = res.data.result;
        this.countDown();
        // if (this.isWeixin) {
        //   document.title = this.orderInfo.paid ? "支付成功" : "支付失败";
        // } else {
        //   document.title = "订单创建成功";
        // }
      }).catch(res=>{
        console.log(res);
      });
    },
    //倒计时
    countDown: function() {
      const that = this;
      var endTime = that.orderInfo.add_time*1000 + that.orderInfo.down_time;
      var timer = setInterval(function(){
        var startTime = new Date().getTime();
        var yutime = (endTime - startTime)/1000;
        if(yutime>=0) {
          var date = parseInt(yutime/(60*60*24));
          if(date<10) {
            date = '0' + date;
          }
          var hour = parseInt(yutime/(60*60)%24);
          if(hour<10) {
            hour = '0' + hour;
          }
          var min = parseInt(yutime/60%60);
          if(min<10) {
            min = '0' + min;
          }
          var sec = parseInt(yutime%60);
          if(sec<10) {
            sec = '0' + sec;
          }
          var time = date + ':'+ hour + ':'+ min + ':' + sec;
          that.msg = time;
        }
        else {
          that.msg = '订单已超时';
          clearInterval(timer);
        }
      },1000)
    },

  }
}


</script>
<style type="text/css">
.payment-status { height:100%; }

.payment-status .header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0.96rem;
  font-size: 0.3rem;
  color: #050505;
  background-color: #fff;
  z-index: 11;
  border-bottom: 0.01rem solid #eee;
  position:relative;
}
.payment-status .header .black {
  width: 7px;
  height: 14px;
  margin-left: 10px;
  margin-top:15px;
}
.payment-status .header .black img {
  width: 100%;
  height: 100%;
}
.payment-status .header p {
  width: calc(100% - 17px);
  text-align: center;
  color: #333;
  line-height: 0.96rem;
}
.payment-status .header p.admin {
  width:auto;
  position: absolute;
  right: 8px;
  top: 0px;
  font-size: 14px;
  color: #666;
}

</style>
